import { Component, ElementRef, Input, ViewChild, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent implements AfterViewChecked {
  @ViewChild('conversationContainer') conversationContainer!: ElementRef<HTMLDivElement>;

  @Input() messages = [];

  ngAfterViewChecked(): void {
    const calculatedTop = this.conversationContainer.nativeElement.scrollHeight 
        - this.conversationContainer.nativeElement.clientHeight;
    if (calculatedTop != this.conversationContainer.nativeElement.scrollTop)
      this.conversationContainer.nativeElement.scrollTop = calculatedTop;
  }
}
