import { Component, Input } from '@angular/core';
import { ChatBotService } from 'app/chat-bot.service';

@Component({
  selector: 'app-chatbot',
  providers: [ChatBotService],
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent {
  public messages = [];
  chatboxOpen: boolean = false;
  thread_id: string = undefined;
  firstTimeChatboxOpen: boolean = true;
  @Input() questionnaire: any;  // Property to receive data from parent
  isApiCallInProgress:boolean = false;

  constructor(private chatBotService: ChatBotService) { }

  ngOnInit() {
    this.messages = this.chatBotService.messages;
  }

  toggleChatbox() {
    this.chatboxOpen = !this.chatboxOpen;
    if (this.firstTimeChatboxOpen) {
      this.handlePromptChange(null)
    }
    this.firstTimeChatboxOpen = false;
  }

  handlePromptChange($event: any) {
    // Add user message
    if($event !== null){
      this.messages.push({
        from: 'user',
        text: $event
      });
    }

    // Add loading message
    this.messages.push({
      from: 'loading',
      text: '...'
    });

    this.isApiCallInProgress = true;
    // Submit the prompt to the chatbot service
    this.chatBotService.submitPrompt($event, this.questionnaire.id, this.thread_id).subscribe({
      next: (res) => {
        // Add bot response after a delay
        console.log(res);
        
        setTimeout(() => {
          // Remove all loading messages before adding the bot's response
          this.messages = this.messages.filter(message => message.from !== 'loading');
          let msg = null;
          if(res.error){
            msg = res.error
          }else{
            this.thread_id = res.response.thread_id;
            msg = res.response.message.trim()
          }
          this.messages.push({
            from: 'bot',
            text: msg
          });

          this.isApiCallInProgress = false;
        }, 500);
      },
      error: (err) => {
        
        // Add error message after a delay
        setTimeout(() => {
          // Remove all loading messages if an error occurs
          this.messages = this.messages.filter(message => message.from !== 'loading');

          this.messages.push({
            from: 'bot',
            text: "Something went wrong, please try again"
          });

          this.isApiCallInProgress = false;
        }, 500);
      }
    });
  }
}