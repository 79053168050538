import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AjaxService } from './services/ajax.service';
import { Router } from '@angular/router';

const chatApiUrl: string = 'https://workships-3-staging-18e6f71528e2.herokuapp.com/api/assistant/chat';
const authToken: string = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo5NiwiZXhwIjoxNzQyOTIyNDg2fQ.w1OgTKAduTvuvkvrElLyDuXiI6XUGgvedRMdLvwh2IA';

@Injectable({
  providedIn: 'root'
})
export class ChatBotService {
  public messages = [];

  constructor(private http: HttpClient, private router: Router) { }

  // Submit message to Workships API
  submitPrompt(message: string, questionnaireId: string, threadId?: string): Observable<any> {
    const jwt = localStorage.getItem('jwtToken')

    if (jwt === null) {
      this.router.navigate(['login'])
      return
    }

    const token = JSON.parse(jwt)['auth_token']
    const completeUrl = AjaxService.getServerUrl() + 'api/assistant/chat'

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);

    const body = {
      message: {
        message: message,
        questionnaire_id: questionnaireId,
        thread_id: threadId ? threadId : undefined // Conditionally add thread_id if it exists
      }
    };

    return this.http.post<any>(
      completeUrl,
      body,
      { headers }
    );
  }
}
