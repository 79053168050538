import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-prompt',
  templateUrl: './app-prompt.component.html',
  styleUrls: ['./app-prompt.component.scss']
})
export class PromptComponent {
  userInput: string = '';
  @Output() textChange = new EventEmitter<string>();
  @Input() isApiCallInProgress: boolean;

  constructor() {}

  sendMessage() {
    if (this.userInput.trim() !== '') {
      this.textChange.emit(this.userInput.trim());
      this.userInput = ''; // Clear input after sending message
    }
  }
}
